import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { Http, RequestOptions, Headers  } from '@angular/http';
// import { HttpClientModule, Option } from "@angular/common/http";
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ElasticsearchService {

  options = new RequestOptions({
    headers: new Headers({
      'Content-Type': 'application/json; charset=utf-8',
    })
  });

  public curseur = 0;

  constructor(
    public http: Http,
    public globalService: GlobalService,
  ) { 

   
   // this.init();

  }
  /*async init(){
    await this.allAdd('additifs');
  }*/

  derniers(collection: string) {
    const headers ='Content-Type: application/json';
    let body = {};
    body = {
      "from" : 0, "size" : 6,
        "query": {
          "query_string": {
             "query": "(statut:true)"
          }
      },
        "sort": [
          {
              "SCIENCE.date_update._seconds": "desc"
          }
      ]
  }
    return this.http.post(`${environment.elasticsearchURL}/${collection}/_search`,body,headers);
  }
  
  
  random(collection: string) {
    const headers ='Content-Type: application/json';
    let body = {};
    body = {
      "from" : 0, "size" : 6,
      "query": {
        "function_score": {
          "random_score" : {}
        }
      }
    }
    return this.http.post(`${environment.elasticsearchURL}/${collection}/_search`,body,headers);
  }

  rechercher(limite:number = 9,plus:number = 0,ini:boolean=false) {

    console.log('RECHERCHER('+limite+','+plus+','+ini+')');
    if(this.curseur===0 || ini) {
      this.curseur = limite;
    }
    if(plus !=0){
      this.curseur+=plus;
    }


      this.search('additifs').subscribe(res => {
        console.log(res);
        if (res.json()) {
          //console.log("HITS", res.json().hits);
          this.globalService.query = [];
          this.globalService.total = res.json().hits.total.value;
          //console.log('TOTAL = '+ this.globalService.total);
          res.json().hits.hits.forEach(hit => {
            let element = hit._source;
            this.globalService.query.push(element);
          });
        }
      },
      error => {
        this.globalService.total = -1;
        this.globalService.query = [];
        console.error('REQUEST ERROR', error);
      });
  }

  search(collection: string ) {
    //q:((nom:association)OR(acronyme:association))AND(reseau.secteurs:Economie)AND(reseau.services:Rencontres%20business)
    let query = (this.globalService.search && this.globalService.search.length > 0) ? `*${this.globalService.search}*` : '*';
    query = '('+ query + ') AND (statut:true) ';
    if(this.globalService.elastic.media.code != null){
      query +=  ' AND (MEDIAS:"'+ this.globalService.elastic.media.code + '-' + this.globalService.elastic.media.coul +'") ';
    }
    console.log('query', query);
    const headers ='Content-Type: application/json';

    let body = {
      from: 0,
      size: this.curseur,
      sort: [
        {
            "additifTRI_float": "asc"
        }
      ],
      query: {
        query_string: {
          query: query,
          
          fields: [
                    "additifID",
                    "additifNom",
                    "additif",
                    "categorie",
                    "NOMS",
                    ]
         }}
        }

    // let body = {
    //   from: 0,
    //   size: this.curseur,
    //       query: {
    //                "bool":{
    //                  "must" : [
    //                       {
    //                        "multi_match": {
    //                          "query": query,
    //                          // query: `statut:true AND ${query}`,
    //                          "type":  "phrase_prefix",
    //                          "fields": [
    //                                     "additifID",
    //                                     "additif",
    //                                     "catégorie",
    //                                     "NOMS",
    //                                     ]
    //                        }
    //                      }
    //                    ]
    //                }
    //              }
    //     }


    /*if(this.globalService.elastic.diag_MEDIA != 'Toutes') {
      body.query.query_string.query += ' AND (MEDIA.DIAG:'+this.globalService.elastic.diag_MEDIA+' OR SCIENCE.DIAG:'+this.globalService.elastic.diag_SCIENCE+')';
    }*/
    if(this.globalService.elastic.diag_MEDIA != 'Toutes' && this.globalService.elastic.diag_SCIENCE != 'Toutes') {
      body.query.query_string.query += ' AND (MEDIA.DIAG:'+this.globalService.elastic.diag_MEDIA+' AND SCIENCE.DIAG:'+this.globalService.elastic.diag_SCIENCE+')';
    }else{
      if(this.globalService.elastic.diag_MEDIA != 'Toutes') {
        body.query.query_string.query += ' AND (MEDIA.DIAG:'+this.globalService.elastic.diag_MEDIA+')';
      }
      if(this.globalService.elastic.diag_SCIENCE != 'Toutes') {
        body.query.query_string.query += ' AND (SCIENCE.DIAG:'+this.globalService.elastic.diag_SCIENCE+')';
      }
    }

    

    /*
    if (this.globalService.elastic.category != 'Toutes' && this.globalService.elastic.diag_MEDIA != 'Toutes') {

      console.log(this.globalService.elastic);

      body.query.query_string.query += ' AND '+this.globalService.elastic.category+'.DIAG:'+this.globalService.elastic.diag_MEDIA+'';

    }
    */

    console.log('BODY' ,  body );
   
    // "(*) AND (statut:true)  AND (MEDIA.DIAG:vert OR SCIENCE.DIAG:vert) AND MEDIA.DIAG:vert"

    // return this.http.get(`${environment.elasticsearchURL}/${collection}/_search` + URLparam,headers);
    collection = collection == "additifs" ? "" : collection;
    return this.http.post(`${environment.elasticsearchURL}/${collection}/_search`,body,headers);
  }

  searchAdditifs() {
    let query = {
      query: {
        query_string: {
          query: `${this.globalService.search}`,
          fields: ['acronyme', 'description', 'nom']
        }
      }
    }
    /*
    if (this.globalService.secteur && this.globalService.secteur != 'tous') {
      let completeSecteur = this.globalService.secteurs.filter(v => v.slug == this.globalService.secteur)[0].name;
      query.query.query_string.query += ` AND reseau.secteurs:${completeSecteur}`;
    }
    if (this.globalService.objectif && this.globalService.objectif != 'tous') {
      let completeObjectif = this.globalService.objectifs.filter(v => v.slug == this.globalService.objectif)[0].name;
      query.query.query_string.query += ` AND reseau.objectifs:${completeObjectif}`;
    }
    */
    let newOpt = this.options;
    newOpt['body'] = JSON.stringify(query);
    console.log('searchadditifs', newOpt);
    return this.http.get(`${environment.elasticsearchURL}/additifs/_search`, newOpt);
  }

  searchInIndex(collection: string, query: string) {
    return this.http.get(`${environment.elasticsearchURL}/${collection}/_search?q=(nom:*${query}*)OR(description:*${query}*)OR(acronyme:*${query}*)`, this.options);
  }

  searchForGetThemAll(collection: string) {
    let query = {
                  "query": {
                      "match_all": {}
                  }
              };
    return this.http.post(`${environment.elasticsearchURL}/${collection}/_search`, JSON.stringify(query), this.options);
  }

  ///  A SUPPRIMER
  /*
  createIndex() {
    return this.http.put(environment.elasticsearchURL, null, this.options);
  }

  addInIndex(collection: string, doc: string, data: {}) {
    console.log('addInIndex '+doc,JSON.stringify(data) )
    return this.http.put(`${environment.elasticsearchURL}/${collection}/${doc}`, JSON.stringify(data), this.options);
  }

  allDelete(collection){
    this.http.delete(environment.elasticsearchURL, this.options).subscribe( (result)=>{
      console.log('allDelete()',result);
      //this.allAdd(collection);
    })
  }

  async allAdd(collection) {
    console.log('allAdd()');
     await firebase.firestore().collection(collection).get().then(async docs => {
       docs.docs.forEach(doc => {
         console.log(doc.data());
        //if(doc.data().statut){
          console.log('indexableA', doc.data().SCIENCE.DIAG);
          const indexable = {
            additifID : doc.data().additifID,
            statut : doc.data().statut,
            additifNom : doc.data().additifNom,
            additif : doc.data().additif,
            categorie : doc.data().categorie,
            NOMS : doc.data().NOMS,
            MEDIA: {
              DIAG: doc.data().MEDIA.DIAG
            },
            SCIENCE: {
              DIAG: doc.data().SCIENCE.DIAG
            },
            search : doc.data().search,
          }

          console.log('indexableB', indexable);

          this.addInIndex(collection, doc.data().additifID, indexable).subscribe(res => {
            console.log(res.json());
          });
       // }
         console.log('allAdd()', 'ok');
       });
     });
   }
   */
}
